import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'

export const EstatePlantsContainer = styled(Container)`
  max-width: none;
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.xxl.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
  ${media.sm.max} {
    padding: 48px 0px;
    gap: 32px;
  }
`

export const StyledH1Light = styled(H1Light)`
  max-width: 676px;
  margin: 0px auto;

  ${media.sm.max} {
    text-align: left;
    width: 272px;
    padding-left: 24px;

    margin: 0px;
    margin-right: auto;
  }
`

export const HeroContainer = styled(Container)`
  max-width: none;
  height: 640px;

  ${media.lg.max} {
    height: 480px;
  }
`

export const ImageWraper = styled.div`
  width: 100%;
  height: 100%;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const TextContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  gap: 128px;

  & h2 {
    min-width: 212px;
  }

  ${media.xxl.max} {
    padding: 0px 24px;
  }
  ${media.lg.max} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
  }
`
