import React from 'react'

import { ImageType } from 'types/image'

import { H3Bold, TextBody1 } from 'components/atoms/Typography'
import { LazyImage } from 'components/atoms/Image'
import {
  Ball,
  ImageWraper,
  InvestmentInformationContainer,
  InvestmentInformationContent,
  OurHistoryContainer,
  StyledH1Light,
  TextConatiner,
} from './InvestmentInformation.styles'

type TileType = {
  heading: string
  description: string
  image: ImageType
}

type InvestmentInformationProps = {
  heading: string
  tiles: TileType[]
}

export const InvestmentInformation: React.FC<InvestmentInformationProps> = ({
  heading,
  tiles,
}) => {
  return (
    <InvestmentInformationContainer padding={[72, 50]} margin={[0, 'auto']}>
      <StyledH1Light
        as="h2"
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <InvestmentInformationContent>
        <OurHistoryContainer>
          <ImageWraper>
            <LazyImage
              src={tiles[0].image.src}
              alt={tiles[0].image.alt!}
              objectFit="cover"
            />
          </ImageWraper>

          <TextConatiner>
            <H3Bold
              color="black"
              dangerouslySetInnerHTML={{
                __html: tiles[0].heading,
              }}
            />
            <Ball />
            <TextBody1
              dangerouslySetInnerHTML={{
                __html: tiles[0].description,
              }}
            />
          </TextConatiner>
        </OurHistoryContainer>
        <OurHistoryContainer>
          <ImageWraper>
            <LazyImage
              src={tiles[1].image.src}
              alt={tiles[1].image.alt!}
              objectFit="cover"
            />
          </ImageWraper>

          <TextConatiner>
            <H3Bold
              color="black"
              dangerouslySetInnerHTML={{
                __html: tiles[1].heading,
              }}
            />
            <Ball />
            <TextBody1
              dangerouslySetInnerHTML={{
                __html: tiles[1].description,
              }}
            />
          </TextConatiner>
        </OurHistoryContainer>
      </InvestmentInformationContent>
    </InvestmentInformationContainer>
  )
}
