import React from 'react'

import { LazyImage } from 'components/atoms/Image'
import { H3Bold, TextBody1 } from 'components/atoms/Typography'
import { ImageType } from 'types/image'
import {
  EstatePlantsContainer,
  HeroContainer,
  ImageWraper,
  StyledH1Light,
  TextContainer,
} from './EstatePlans.styles'

type EstatePlantsProps = {
  heading: string
  title: string
  description: string
  image: ImageType
}

export const EstatePlants: React.FC<EstatePlantsProps> = ({
  heading,
  title,
  description,
  image,
}) => {
  return (
    <EstatePlantsContainer padding={[100, 32]} margin={[0, 'auto']}>
      <StyledH1Light
        as="h2"
        align="center"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <HeroContainer>
        <ImageWraper>
          <LazyImage src={image.src} alt={image.alt!} objectFit="cover" />
        </ImageWraper>
      </HeroContainer>
      <TextContainer margin={[0, 'auto']}>
        <H3Bold
          as="h3"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <TextBody1 dangerouslySetInnerHTML={{ __html: description }} />
      </TextContainer>
    </EstatePlantsContainer>
  )
}
