import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'
import { H1Light } from 'components/atoms/Typography'

export const InvestmentInformationContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.sm.max} {
    padding: 50px 24px;
    gap: 32px;
  }
`

export const InvestmentInformationContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 64px;

  ${media.lg.max} {
    flex-direction: column;
  }
`

export const OurHistoryContainer = styled.div`
  width: 100%;
  max-width: 520px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.lg.max} {
    margin: 0px auto;
  }
`

export const ImageWraper = styled.div`
  width: 100%;

  aspect-ratio: 503/480;

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const TextConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Ball = styled.div`
  content: '';
  width: 24px;
  height: 24px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
`

export const StyledH1Light = styled(H1Light)`
  max-width: 582px;
  margin: 0px auto;

  ${media.sm.max} {
    text-align: left;
    width: 272px;
    margin: 0px;
    margin-right: auto;
  }
`
